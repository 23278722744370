export const MiscellaneousToolInfos = [
  {
    key: 'qrcode-generator',
    category: 'image',
    group: 'qrcode',
    url: '/qrcode-generator',
    isPrivate: false,
  },
  {
    key: 'random-string-generator',
    category: 'random',
    group: 'generator',
    url: '/random-string-generator',
    isPrivate: false,
  },
  {
    key: 'snowflake-id-generator',
    category: 'random',
    group: 'generator',
    url: '/snowflake-id-generator',
    isPrivate: false,
  },
  {
    key: 'draw-lots',
    category: 'random',
    group: 'choice',
    url: '/draw-lots',
    isPrivate: false,
  },
  {
    key: 'online-clock',
    category: 'time',
    group: null,
    url: '/online-clock',
    isPrivate: false,
  },
  {
    key: 'timestamp-converter',
    category: 'time',
    group: null,
    url: '/timestamp-converter',
    isPrivate: false,
  },
  {
    key: 'color-converter',
    category: 'color',
    group: null,
    url: '/color-converter',
    isPrivate: false,
  },
  {
    key: 'mermaid-editor',
    category: 'image',
    group: 'diagram',
    url: '/mermaid-editor',
    isPrivate: false,
  },
  {
    key: 'json-viewer',
    category: 'viewer',
    group: 'data-viewer',
    url: '/json-viewer',
    isPrivate: false,
  },
  {
    key: 'what-is-my-ip',
    category: 'others',
    group: 'network',
    url: '/what-is-my-ip',
    isPrivate: false,
  },
  {
    key: 'screen-tester',
    category: 'others',
    group: 'hardware',
    url: '/screen-tester',
    isPrivate: false,
  },
]
