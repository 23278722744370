'use client'

import React from 'react'
import {css, useTheme} from '@emotion/react'
import {useDebounce} from 'use-debounce'
import Fuse from 'fuse.js'
import fp from 'lodash/fp'
import {useTranslations} from 'next-intl'
import Tool from './Tool'
import {Chip} from '@mui/material'

import {ToolCategories} from '../../../../../config/categories.config'
import SearchField from '../../../../../components/elements/SearchField'
import Paper from '../../../../../components/elements/Paper'
import useAllToolInfos from '../../../../../hooks/useAllToolInfos'
import getRecentUsedToolKeys from '../../../../../app/utils/getRecentUsedToolKeys'
import Button from '../../../../../components/elements/Button'
import clearRecentUsedToolKeys from '../../../../../app/utils/clearRecentUsedToolKeys'

export default function HomePage({query}) {
  const theme = useTheme()
  const styles = {
    root: css`
    `,

    control: css`
      margin-top: 8px;
    `,

    searchField: css`
      flex: 1;
      max-width: 400px;

      & > div {
        height: 48px;
        padding: 8px 16px;
      }
    `,

    mobileContent: css`
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      gap: 16px;
    `,

    desktopContent: css`
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .masonry {
        display: flex;
        gap: 16px;
      }

      .masonry-column {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    `,

    section: css`
      padding: 24px;
      background: ${theme.palette.surface.main};
    `,

    sectionHeader: css`
      margin-bottom: 16px;

      display: flex;
      gap: 16px;
      justify-content: space-between;
    `,

    sectionTitle: css`
      font-size: 1.4rem;
      font-weight: 600;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `,

    sectionBody: css`
      display: flex;
      flex-direction: column;
      gap: 16px;
    `,

    adFooter: css`
      margin: 0 auto;
      max-width: 728px;
    `,
  }

  const t = useTranslations()

  const allToolInfos = useAllToolInfos()

  const [queryText, setQueryText] = React.useState(query)
  const onSearchTextChange = React.useCallback((queryText) => {
    setQueryText(queryText)
  }, [setQueryText])

  const [debouncedSearchText] = useDebounce(queryText, 20,)

  const [groupFilter, setGroupFilter] = React.useState('')

  const filteredToolInfos = React.useMemo(() => {
    if (debouncedSearchText) {
      const fuse = new Fuse(allToolInfos, {
        keys: [
          {name: 'label', weight: 1},
        ],
        includeMatches: true,
        threshold: 0.1,
      })

      return highlight(fuse.search(debouncedSearchText))
    } else {
      return allToolInfos
    }
  }, [allToolInfos, debouncedSearchText])

  const [recentUsedToolKeys, setRecentUsedToolKeys] = React.useState(getRecentUsedToolKeys())

  const onRecentUsedToolClearButtonClick = () => {
    clearRecentUsedToolKeys()
    setRecentUsedToolKeys(getRecentUsedToolKeys())
  }

  const categoryToolInfos = React.useMemo(() => (
    ToolCategories
      .map(toolCategory => ({
        key: toolCategory,
        value: toolCategory,
        label: t(`tool-category.${toolCategory}`),
        tools: toolCategory === 'recent-used' ? (
          filteredToolInfos
            .filter(onlineTool => !groupFilter || onlineTool.group === groupFilter)
            .filter(onlineTool => recentUsedToolKeys.includes(onlineTool.key))
        ) : (
          filteredToolInfos
            .filter(onlineTool => onlineTool.category === toolCategory)
            .filter(onlineTool => !groupFilter || onlineTool.group === groupFilter)
        )
      }))
      .filter(toolCategory => toolCategory.tools.length > 0)
  ), [t, filteredToolInfos, groupFilter, recentUsedToolKeys])

  return (
    <div css={styles.root}>
      <div css={styles.control}>
        <div id="home-search" css={styles.searchField}>
          <SearchField
            placeholder={t('home.search-placeholder')}
            query={queryText}
            onQueryChange={onSearchTextChange}
          />
        </div>
      </div>
      <div css={styles.desktopContent}>
        {categoryToolInfos.map(categoryToolInfo => (
          <Paper key={categoryToolInfo.key} css={styles.section}>
            <div css={styles.sectionHeader}>
              <div css={css`
                display: flex;
                gap: 16px;
              `}>
                <div css={styles.sectionTitle}>{categoryToolInfo.label}</div>
                {groupFilter && <Chip label={t(`tool-group.${groupFilter}`)} onDelete={() => setGroupFilter('')}/>}
              </div>
              {categoryToolInfo.key === 'recent-used' &&
                <Button secondary onClick={onRecentUsedToolClearButtonClick}>{t('home.clear')}</Button>}
            </div>
            <div
              css={css`
                display: grid;
                grid-gap: 16px;
                grid-template-columns: repeat(4, 1fr);

                @media (max-width: 1400px) {
                  grid-template-columns: repeat(3, 1fr);
                }

                @media (max-width: 1200px) {
                  grid-template-columns: repeat(2, 1fr);
                }

                @media (max-width: 900px) {
                  grid-template-columns: repeat(1, 1fr);
                }
              `}
            >
              {categoryToolInfo.tools.map(toolInfo => (
                <Tool
                  key={toolInfo.key}
                  info={toolInfo}
                  setGroupFilter={setGroupFilter}
                />
              ))}
            </div>
          </Paper>
        ))}
      </div>

      {/*<div css={styles.adFooter}>*/}
      {/*  <Adsense*/}
      {/*    client="ca-pub-9395644566418596"*/}
      {/*    slot="1014541144"*/}
      {/*    format="auto"*/}
      {/*    style={{*/}
      {/*      display: 'block',*/}

      {/*      width: '100vw',*/}
      {/*      maxWidth: 728,*/}
      {/*      height: 90,*/}
      {/*    }}*/}
      {/*    responsive="true"*/}
      {/*  />*/}
      {/*</div>*/}
    </div>
  )
}


const highlight = (fuseSearchResult) => {
  const generateHighlightedText = (inputText, regions = []) => {
    let content = ''
    let nextUnhighlightedRegionStartingIndex = 0

    regions.forEach(region => {
      const lastRegionNextIndex = region[1] + 1

      content += [
        inputText.substring(nextUnhighlightedRegionStartingIndex, region[0]),
        `<b>`,
        inputText.substring(region[0], lastRegionNextIndex),
        '</b>',
      ].join('')

      nextUnhighlightedRegionStartingIndex = lastRegionNextIndex
    })

    content += inputText.substring(nextUnhighlightedRegionStartingIndex)

    return content
  }

  return fuseSearchResult
    .filter(({matches}) => matches && matches.length)
    .map(({item, matches}) => {
      return fp.flow(
        ...matches.map(match => fp.set(match.key, generateHighlightedText(match.value, match.indices)))
      )(item)
    })
}
