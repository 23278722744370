import {defineRouting} from 'next-intl/routing'
import {createNavigation} from 'next-intl/navigation'

import {DefaultLocale, Locales} from '../../config/locale.config'

export const routing = defineRouting({
  locales: Locales,
  defaultLocale: DefaultLocale,
})

export const {Link, redirect, usePathname, useRouter} = createNavigation(routing)