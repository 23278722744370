const ToolCategories = Object.freeze([
  'all',
  'recent-used',
  'compare',
  'minify',
  'merge',
  'format',
  'data-convert',
  'encode-and-decode',
  'hash',
  'image',
  'time',
  'color',
  'text-edit',
  'random',
  'viewer',
  'others',
  'site',
])

const ToolGroups = Object.freeze([
  'base64',
  'base32',
  'url',
  'md5',
  'sha-family',
  'html',
  'css',
  'js',
  'sql',
  'json',
  'yaml',
  'toml',
  'xml',
  'text',
  'js-object',
  'python-dict',
  'time',
  'color',
  'qrcode',
  'image-convert',
  'diagram',
  'tabs-and-spaces',
  'language',
  'editor',
  'data-viewer',
  'generator',
  'network',
  'hardware',
  'game',
  'site-info',
  'py-dict',
  'choice',
])

module.exports = {
  ToolCategories,
  ToolGroups,
}