export const CodeFormatterInfos = [
  ...[
    'json',
    'yaml',
    'xml',
    'html',
    'js',
    'css',
    'sql',
  ]
    .map(lang => ({
      key: `${lang}-formatter`,
      category: 'format',
      group: lang,
      url: `/${lang}-formatter`,
      isPrivate: false,
    }))
]