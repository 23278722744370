'use client'

export default function getRecentUsedToolKeys() {
  if (typeof window !== "undefined") {
    const value = localStorage.getItem('recent_used_tools')
    if (value) {
      return JSON.parse(value)
    }
  }

  return []
}
