import React from 'react'
import {useTranslations} from 'next-intl'
import {css, useTheme} from '@emotion/react'
import {Chip, Divider} from '@mui/material'

import Paper from '../../../../../components/elements/Paper'
import {Link} from '../../../../../app/i18n/routing'


function Tool({info, setGroupFilter}) {
  const theme = useTheme()
  const styles = {
    root: css`
      min-width: 200px;
      padding: 16px;
    `,

    header: css`
      margin: 0.5rem;

      display: flex;
      justify-content: space-between;
    `,

    title: css`
      font-size: 1.3rem;
      font-weight: 600;
      text-decoration: none;
      color: ${theme.palette.primary.main};

      b {
        color: ${theme.palette.secondary.main};
      }
    `,
  }

  const t = useTranslations()

  const onGroupButtonClick = () => {
    setGroupFilter(info.group)
  }

  return (
    <Paper css={styles.root} component="section">
      <header css={styles.header}>
        <Link css={styles.title} href={info.url}>
          <span dangerouslySetInnerHTML={{__html: info.label}}/>
        </Link>
        {info.group && (
          <Chip
            label={t(`tool-group.${info.group}`)}
            variant="outlined" size="small"
            clickable
            onClick={onGroupButtonClick}
          />
        )}
      </header>
      <Divider/>
      <p>{t(`${info.key}.description`)}</p>
    </Paper>

  )
}

export default React.memo(Tool)
