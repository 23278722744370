import React from 'react'
import {setCookie} from 'cookies-next'

import useKeyPressToggle from '../hooks/useKeyPressToggle'

export const DeveloperModeKey = 'DEVELOPER_MODE'

const DeveloperModeContext = React.createContext(false)

export function DeveloperModeProvider({children}) {
  const developMode = useKeyPressToggle(false, 'F9')
  React.useEffect(() => {
    setCookie(DeveloperModeKey, developMode ? '1' : '0', {
      sameSite: 'strict',
      secure: true,
    })
  }, [developMode])

  return (
    <DeveloperModeContext.Provider value={developMode}>
      {children}
    </DeveloperModeContext.Provider>
  )
}


export function useDeveloperMode() {
  return React.useContext(DeveloperModeContext)
}
